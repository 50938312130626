<div
    ngModelGroup="app-registration-set"
    id="registrationSetForm"
    [ngClass]="isEditMode ? 'editing' : isCreateMode ? 'creating' : 'viewing'"
>
    <!--- CREATE / EDIT --->
    <ng-container *ngIf="(isCreateMode || isEditMode) && !isRetired">
        <div class="form-wrapper">
            <!--- SECTION 1 --->
            <div class="form-entry">
                <!--- supported locales --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.supported_locales' | translate }}</mat-label>
                    <mat-select
                        id="supportedLocale"
                        name="supportedLocale"
                        #supportedLocale="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{
                            failure: supportedLocale.invalid && (supportedLocale.dirty || supportedLocale.touched)
                        }"
                        [(ngModel)]="multiLocaleConfig.supportedLocales"
                        (selectionChange)="handleLocaleSelectionChange()"
                        placeholder="{{ 'registration.create.placeholder.supported_locales' | translate }}"
                        data-automation="supportedLocale"
                        required
                        multiple
                    >
                        <mat-option
                            *ngFor="let locale of locales"
                            [disabled]="limitLocaleSelectList(5, locale)"
                            [value]="locale"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- default locale --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.locale' | translate }}</mat-label>
                    <mat-select
                        id="defaultLocale"
                        name="defaultLocale"
                        #defaultLocale="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: defaultLocale.invalid && (defaultLocale.dirty || defaultLocale.touched) }"
                        [(ngModel)]="registrationSet.defaultLocale"
                        placeholder="{{ 'registration.create.placeholder.locale_selector' | translate }}"
                        data-automation="locale"
                        required
                    >
                        <mat-option
                            *ngFor="let locale of multiLocaleConfig.supportedLocales"
                            [value]="locale.localeName"
                            >{{ locale.displayLabel }}</mat-option
                        >
                    </mat-select>
                    <mat-error *ngIf="defaultLocale.invalid && (defaultLocale.dirty || defaultLocale.touched)">
                        <mat-error *ngIf="defaultLocale.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode" 
            matTooltip="{{ registrationSet.displayLabel }}" matTooltipPosition="above">
                <multi-locale-input
                    #displayLabels="ngModel"
                    name="displayLabel"
                    [(ngModel)]="registrationSet.displayLabels"
                    [inputLabel]="nameLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="namePlaceholder"
                    [required]="true"
                    [maxLength]="100"
                    [id]="'registration_set_display_labels'"
                    data-automation="displayLabel"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry" *ngIf="isEditMode || isCreateMode">
                <multi-locale-input
                    #Descriptions="ngModel"
                    name="descriptions"
                    [(ngModel)]="registrationSet.descriptions"
                    [inputLabel]="descriptionLabel"
                    [locales]="multiLocaleConfig.supportedLocales"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="multiLocaleConfig.defaultLocale"
                    [placeholderText]="descriptionPlaceholder"
                    [required]="false"
                    [id]="'registration_descriptions'"
                    data-automation="descriptions"
                >
                </multi-locale-input>
            </div>

            <div class="form-entry">
                <!--- timezone --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.timezone' | translate }}</mat-label>
                    <mat-select
                        id="timezone"
                        name="timezone"
                        #timezone="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: timezone.invalid && (timezone.dirty || timezone.touched) }"
                        [(ngModel)]="registrationSet.timezone"
                        data-automation="timezone"
                        required
                        placeholder="{{ 'registration.create.placeholder.timezone_selector' | translate }}"
                    >
                        <mat-option *ngFor="let timezone of timezones" [value]="timezone.timezoneName">{{
                            timezone.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="timezone.invalid && (timezone.dirty || timezone.touched)">
                        <mat-error *ngIf="timezone.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- status --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.status' | translate }}</mat-label>
                    <mat-select
                        id="status"
                        name="status"
                        #status="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{ failure: status.invalid && (status.dirty || status.touched) }"
                        [(ngModel)]="registrationSet.status"
                        data-automation="status"
                        required
                    >
                        <mat-option *ngFor="let status of statuses" [value]="status.code">{{
                            status.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="status.invalid && (status.dirty || status.touched)">
                        <mat-error *ngIf="status.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- program --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.program' | translate }}</mat-label>
                    <mat-select
                        id="programSelector"
                        name="programId"
                        #programId="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="appData.loadingPrograms"
                        [ngClass]="{ failure: programId.invalid && (programId.dirty || programId.touched) }"
                        [(ngModel)]="registrationSet.programId"
                        (selectionChange)="onProgramChange($event.value)"
                        [disabled]="isEditMode"
                        data-automation="programSelector"
                        data-url-persist
                        required
                        placeholder="{{
                            (appData.programs && appData.programs.length > 0
                                ? 'registration.create.placeholder.program_selector'
                                : 'registration.create.placeholder.no_programs'
                            ) | translate
                        }}"
                    >
                        <mat-option *ngFor="let program of appData.programs" [value]="program.id" id="{{ program.id }}">
                            {{ program.displayLabel }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="programId.invalid && (programId.dirty || programId.touched)">
                        <mat-error *ngIf="programId.errors.required">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingPrograms" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingPrograms" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry" *ngIf="isCreateMode || isEditMode">
                <!--- site --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.site' | translate }}</mat-label>
                    <mat-select
                        id="siteSelector"
                        name="site"
                        #site="ngModel"
                        class="input-entry create-select"
                        [class.loading-field]="appData.loadingSites"
                        [ngClass]="{ failure: site.invalid && (site.dirty || site.touched) }"
                        [(ngModel)]="selectedSite"
                        (selectionChange)="onSiteChange($event.value)"
                        [disabled]="!org || isEditMode"
                        required
                        data-automation="siteSelector"
                        data-url-persist
                        placeholder="{{
                            (appData.sites.length > 0
                                ? 'registration.create.placeholder.site_selector'
                                : 'registration.create.placeholder.no_sites'
                            ) | translate
                        }}"
                        matTooltip="{{selectedSite_label}}" matTooltipPosition="above"
                    >
                        <mat-option *ngFor="let site of appData.sites" [value]="site.id" id="{{ site.id }}"
                        matTooltip="{{site.displayLabel}}">{{
                            site.displayLabel
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="site.invalid && (site.dirty || site.touched)">
                        <mat-error *ngIf="site.errors.required || site.errors.selectValid">
                            <span>{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                    <mat-hint *ngIf="appData.loadingSites" align="end">{{
                        'registration.loading' | translate
                    }}</mat-hint>
                    <mat-spinner *ngIf="appData.loadingSites" matSuffix diameter="23"></mat-spinner>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- Minimum value --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.minimum_value' | translate }}</mat-label>
                    <input
                        matInput
                        id="minimumValue"
                        type="number"
                        step="1"
                        #minimumValue="ngModel"
                        class="input-entry"
                        [ngClass]="{
                            failure: minimumValue.invalid && (minimumValue.dirty || minimumValue.touched)
                        }"
                        name="minimumValue"
                        placeholder="0"
                        maxlength="16"
                        [(ngModel)]="registrationSet.minimumValue"
                        data-automation="minimumValue"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="minimumValue.invalid">
                        <mat-error *ngIf="minimumValue.errors.required">
                            <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- Maximum value --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration.maximum_value' | translate }}</mat-label>
                    <input
                        matInput
                        id="maximumValue"
                        type="number"
                        step="1"
                        #maximumValue="ngModel"
                        class="input-entry"
                        [ngClass]="{
                            failure: maximumValue.invalid && (maximumValue.dirty || maximumValue.touched)
                        }"
                        name="maximumValue"
                        placeholder="0"
                        maxlength="16"
                        [(ngModel)]="registrationSet.maximumValue"
                        data-automation="maximumValue"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="maximumValue.invalid">
                        <mat-error *ngIf="maximumValue.errors.required">
                            <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- Percent change --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration_set.percent_change' | translate }}</mat-label>
                    <input
                        matInput
                        id="percentChange"
                        type="number"
                        step="1"
                        #percentChange="ngModel"
                        class="input-entry"
                        [ngClass]="{
                            failure: percentChange.invalid && (percentChange.dirty || percentChange.touched)
                        }"
                        name="percentChange"
                        placeholder="0"
                        maxlength="16"
                        min="0"
                        [(ngModel)]="registrationSet.percentChange"
                        data-automation="percentChange"
                        data-url-persist
                        required
                    />
                    <mat-error *ngIf="percentChange.invalid">
                        <mat-error *ngIf="percentChange.errors.required">
                            <span class="input-entry">{{ 'registration.validation.required' | translate }}</span>
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- Offer dimension --->
                <mat-form-field appearance="outline" floatLabel="always">
                    <mat-label>{{ 'registration_set.offer_dimension' | translate }}</mat-label>
                    <mat-select
                        id="offerDimension"
                        name="offerDimension"
                        #offerDimension="ngModel"
                        class="input-entry create-select"
                        [ngClass]="{
                            failure: offerDimension.invalid && (offerDimension.dirty || offerDimension.touched)
                        }"
                        [(ngModel)]="registrationSet.offerDimension"
                        placeholder="{{ 'registration.create.placeholder.offer_dimension_selector' | translate }}"
                        data-automation="offerDimension"
                        required
                    >
                        <mat-option *ngFor="let unit of offerUnits" [value]="unit.code">{{
                            unit.displayLabel | translate
                        }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="offerDimension.invalid && (offerDimension.dirty || offerDimension.touched)">
                        <mat-error *ngIf="offerDimension.errors.pattern">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-entry">
                <!--- Offer at Set value --->
                <mat-checkbox
                    name="offerAtSet"
                    [(ngModel)]="registrationSet.offerAtSet"
                    data-automation="offerAtSet"
                    id="offerAtSet"
                >
                    {{ 'registration_set.offer_at_set' | translate }}
                </mat-checkbox>
            </div>

            <h4 class="form-header" data-automation="DefaultScheduleConf">
                {{ 'registration_set.default_schedule_conf.title' | translate }}
            </h4>
            <div class="form-group">
                <div class="form-entry">
                    <!-- Apply Default Offers -->
                    <mat-checkbox
                        name="applyDefaultOffers"
                        [(ngModel)]="registrationSet.defaultScheduleConf.applyDefaultOffers"
                        data-automation="applyDefaultOffers"
                        id="applyDefaultOffers"
                    >
                        {{ 'registration_set.default_schedule_conf.apply_default_offers' | translate }}
                    </mat-checkbox>
                </div>
            </div>
            <br />
            <div
                class="form-group"
                ngModelGroup="defaultScheduleConf"
                #defaultScheduleDates="ngModelGroup"
                startEndDateValidator
                [startControl]="defaultScheduleStart"
                [endControl]="defaultScheduleEnd"
            >
                <!--- Default Schedule Start --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{
                            'registration_set.default_schedule_conf.default_schedule_start' | translate
                        }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerStart"
                            [(ngModel)]="registrationSet.defaultScheduleConf.defaultScheduleStart"
                            [disabled]="!registrationSet.defaultScheduleConf?.applyDefaultOffers"
                            #defaultScheduleStart="ngModel"
                            name="defaultScheduleStart"
                            id="defaultScheduleStart"
                            placeholder="{{ 'registration.create.placeholder.select_date' | translate }}"
                            data-automation="defaultScheduleStart"
                            data-url-persist
                        />
                        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                        <mat-error *ngIf="defaultScheduleStart.invalid">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <!--- Default Schedule End --->
                <div class="form-entry">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{
                            'registration_set.default_schedule_conf.default_schedule_end' | translate
                        }}</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerEnd"
                            [(ngModel)]="registrationSet.defaultScheduleConf.defaultScheduleEnd"
                            [disabled]="!registrationSet.defaultScheduleConf.applyDefaultOffers"
                            #defaultScheduleEnd="ngModel"
                            name="defaultScheduleEnd"
                            id="defaultScheduleEnd"
                            placeholder="{{ 'registration.create.placeholder.select_date' | translate }}"
                            data-automation="defaultScheduleEnd"
                            data-url-persist
                        />
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                        <mat-error *ngIf="defaultScheduleEnd.invalid">
                            {{ 'registration.validation.required' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <mat-error class="group-error" *ngIf="defaultScheduleDates.invalid">
                    <mat-error *ngIf="defaultScheduleDates.errors && defaultScheduleDates.errors.startEndDateValid">
                        <span class="input-title"></span>
                        <span class="input-entry" data-automation="validationMessage">{{
                            'registration.validation.start_end_date' | translate
                        }}</span>
                    </mat-error>
                </mat-error>
            </div>

            <h4 class="form-header">{{ 'registration_set.registrations' | translate }}</h4>
            <div class="form-group">
                <div class="mat-form-field-container">
                    <mat-form-field appearance="outline" floatLabel="always">
                        <mat-label>{{ 'registration.registrations' | translate }}</mat-label>
                        <mat-select
                            id="registrationSelector"
                            name="registrations"
                            #registrationIds="ngModel"
                            [(ngModel)]="registrationSet.registrationIds"
                            multiple
                            required
                            data-automation="registrations"
                            (selectionChange)="handleRegistrationSelection()"
                            [class.loading-field]="loadingRegistrations"
                            [placeholder]="
                                (filteredRegistrations.length > 0
                                    ? 'registration_set.placeholder.registrations'
                                    : 'registration_set.placeholder.no_registrations'
                                ) | translate
                            "
                        >
                            <mat-option *ngFor="let registration of filteredRegistrations" [value]="registration.id">
                                {{ registration.displayLabel }}
                            </mat-option>
                        </mat-select>
                        <mat-error
                            *ngIf="registrationIds.invalid && (registrationIds.dirty || registrationIds.touched)"
                        >
                            <mat-error *ngIf="registrationIds.errors.required || registrationIds.errors.selectValid">
                                <span>{{ 'registration.validation.required' | translate }}</span>
                            </mat-error>
                        </mat-error>
                        <mat-hint *ngIf="loadingRegistrations" align="end">{{
                            'registration.loading' | translate
                        }}</mat-hint>
                        <mat-spinner *ngIf="loadingRegistrations" matSuffix diameter="23"></mat-spinner>
                    </mat-form-field>
                </div>
                <ul
                    *ngIf="registrationSet.registrationIds && registrationSet.registrationIds.length > 0"
                    class="selected-list"
                >
                    <li *ngFor="let registrationId of registrationSet.registrationIds; let i = index">
                        <div
                            class="remove-btn"
                            (click)="removeRegistration(registrationId)"
                            data-automation="removeReg"
                        >
                            <mat-icon size="12">remove_circle</mat-icon>
                        </div>
                        <div class="label" data-automation="registrationListLabels">
                            {{ getRegistrationDisplayLabel(registrationId) }}
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </ng-container>
    <!--- VIEW --->
    <ng-container *ngIf="isViewMode || isRetired">
        <div class="form-wrapper">
            <!--- NAME --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration_set.registration_set_name' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="name">
                    <span matTooltip="{{ registrationSet.displayLabel }}" matTooltipPosition="above">{{ registrationSet.displayLabel }}</span>
                </mat-label>
            </div>

            <!--- Locale --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.locale' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="locale">
                    {{ getLocaleForRegistrationSet().displayLabel }}
                </mat-label>
            </div>

            <!--- Timezone --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.timezone' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="timezone">
                    {{ getTimezoneForRegistrationSet().displayLabel }}
                </mat-label>
            </div>

            <!--- PROGRAM --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.program' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="program">
                    {{ getProgramForRegistrationSet().displayLabel }}
                </mat-label>
            </div>

            <!--- Status --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.status' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="status">
                    {{ getStatusForRegistrationSet().displayLabel }}
                </mat-label>
            </div>

            <!--- Description --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.description' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="description">
                    {{ registrationSet.description }}
                </mat-label>
            </div>

            <!--- Minimum value --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.minimum_value' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="minimumValue">
                    {{ registrationSet.minimumValue ? registrationSet.minimumValue : 0 }}
                </mat-label>
            </div>

            <!--- Maximum value --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration.maximum_value' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="maximumValue">
                    {{ registrationSet.maximumValue ? registrationSet.maximumValue : 0 }}
                </mat-label>
            </div>

            <!--- Offer at Set value --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration_set.offer_at_set' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="offerAtSet">
                    {{ (registrationSet.offerAtSet ? 'registration.true' : 'registration.false') | translate }}
                </mat-label>
            </div>

            <!--- Percent Change --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration_set.percent_change' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="percentChange">
                    {{ registrationSet.percentChange ? registrationSet.percentChange : 0 }}
                </mat-label>
            </div>

            <!--- Offer dimension --->
            <div class="form-entry">
                <mat-label class="info-title">{{ 'registration_set.offer_dimension' | translate }}:</mat-label>
                <mat-label class="info-value" data-automation="offerDimension">
                    {{ registrationSet.offerDimension ? registrationSet.offerDimension : '' }}
                </mat-label>
            </div>
        </div>
        <ng-container *ngIf="registrationSet.defaultScheduleConf?.applyDefaultOffers">
            <h4 class="form-header" data-automation="DefaultScheduleConf">
                {{ 'registration_set.default_schedule_conf.title' | translate }}
            </h4>
            <div class="form-wrapper">
                <!--- Apply Default Offers --->
                <div class="form-entry">
                    <mat-label class="info-title"
                        >{{ 'registration_set.default_schedule_conf.apply_default_offers' | translate }}:</mat-label
                    >
                    <mat-label class="info-value" data-automation="applyDefaultOffers">
                        {{
                            (registrationSet.defaultScheduleConf.applyDefaultOffers
                                ? 'registration.true'
                                : 'registration.false'
                            ) | translate
                        }}
                    </mat-label>
                </div>
                <div class="form-entry"></div>

                <!--- Default Schedule Start --->
                <div class="form-entry">
                    <mat-label class="info-title"
                        >{{ 'registration_set.default_schedule_conf.default_schedule_start' | translate }}:</mat-label
                    >
                    <mat-label class="info-value" data-automation="defaultScheduleStart">
                        {{ registrationSet.defaultScheduleConf.defaultScheduleStart | date: 'shortDate' }}
                    </mat-label>
                </div>

                <!--- Default Schedule End --->
                <div class="form-entry">
                    <mat-label class="info-title"
                        >{{ 'registration_set.default_schedule_conf.default_schedule_end' | translate }}:</mat-label
                    >
                    <mat-label class="info-value" data-automation="defaultScheduleEnd">
                        {{ registrationSet.defaultScheduleConf.defaultScheduleEnd | date: 'shortDate' }}
                    </mat-label>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="registrationSet.registrations?.length > 0">
            <h4 class="form-header" data-automation="registrations">
                {{ 'registration_set.registrations' | translate }}
            </h4>
            <div class="form-wrapper">
                <ol>
                    <li *ngFor="let reg of registrationSet.registrations; let i = index">
                        {{ i + 1 }}. <a href="/details/{{ reg.id }}/view" target="_blank"> {{ reg.displayLabel }}</a>
                    </li>
                </ol>
            </div>
        </ng-container>
    </ng-container>
</div>
