import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Registration } from '../../shared/model/registration.model';
import { BaselinePoint } from '../../shared/model/baseline-point.model';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleBaseConfig } from '../../shared/model/locale.model';
import { TranslateService } from '@ngx-translate/core';
import { SupportingDataService } from 'src/app/shared/services/supporting-data.service';
import { Subscription } from 'rxjs';
import { FormValidatorService } from '../../shared/services/form-validator.service';
import { BaselineDefinition } from '../../shared/model/baseline-definition.model';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';
import { DeleteBaseLineDialogComponent } from 'src/app/dialogs/deleteBaseLine/delete-dialog-BaseLine.component';
@Component({
  selector: 'app-baseline-point',
  templateUrl: './baseline-point-details.component.html',
  styleUrls: ['./baseline-point-details.component.scss', '../../shared/shared.styles.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaselinePointDetailsComponent implements OnInit {
  channels: any[];
  panelOpenedState: boolean;
  usedChannels: any[] = [];

  loadingBaselinePointTypes = true;
  loadingChannels = true;
  loadingReportingIntervals = true;
  loadingSiteMachines: boolean;
  loadingDataProviders = true;
  loadingBaselineDefinitions = false;

  parent: Registration;
  registrationDisplayLabels: any;
  selectedSiteId = '';
  selectedRegistrationName = '';
  selectedDataProvider: string = '-1';
  dataProviderSelectedByDefault: any;

  localeConfig: LocaleBaseConfig;
  namePlaceholder = '';
  nameLabel = '';
  displayLabelsId = 'baseline_point_display_labels';
  descriptionPlaceholder = '';
  descriptionLabel = '';

  @Output() addDynamicBaselinePoint: EventEmitter<any> = new EventEmitter();
  @Output() deleteDynamicBaselinePoint: EventEmitter<any> = new EventEmitter();
  @Output() updateSelectedBaselinePointChannels: EventEmitter<any> = new EventEmitter();
  @Output() updateSourceIds: EventEmitter<any> = new EventEmitter();
  @ViewChild('baselinePanel') baselinePanel: MatExpansionPanel;

  readonly EDIT = 'edit';
  readonly VIEW = 'view';
  readonly CREATE = 'create';
  private _mode: string;
  private _retired: boolean;
  private _baselinePoint: BaselinePoint;
  private _appData: any;
  private _reportingIntervals: any[];
  private _index: number;
  private _count: number;
  private _selectedBaselineChannels: any[];
  private _sourceIds: any[];
  private _dataProviders: any[];
  private subscriptions: Subscription[] = [];
  private clone: boolean = false;
  firstTime: boolean = true;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    if (this._retired) {
      return this.VIEW;
    }
    return this._mode;
  }

  @Input()
  set retired(retired: boolean) {
    this._retired = retired;
  }

  get isRetired() {
    return this._retired;
  }

  @Input()
  set baselinePoint(baselinePoint: any) {
    this._baselinePoint = baselinePoint;
    this.selectedDataProvider = baselinePoint.dataProviderId;
    if (this.clone) {
      this.baselinePoint.sourceId = '';
    }
  }

  get baselinePoint() {
    return this._baselinePoint;
  }

  @Input()
  set registrationName(name: string) {
    this.selectedRegistrationName = name;
    this.updateDisplayLabel(this.baselinePoint.channelId);
  }

  @Input()
  set registration(registration: Registration) {
    this.parent = registration;
    this.registrationDisplayLabels = registration.displayLabels;
  }

  @Input()
  set multiLocaleConfig(config: any) {
    this.localeConfig = config;
  }

  get multiLocaleConfig() {
    return this.localeConfig;
  }

  @Input()
  set siteId(siteId: string) {
    this.selectedSiteId = siteId;
  }

  @Input()
  set appData(appData: any) {
    this._appData = appData;
  }

  get appData() {
    return this._appData;
  }

  @Input()
  set reportingIntervals(reportingIntervals: any) {
    if (reportingIntervals.length > 0) {
      this.loadingReportingIntervals = false;
    }
    this._reportingIntervals = reportingIntervals;
  }

  get reportingIntervals() {
    return this._reportingIntervals;
  }

  @Input()
  set dataProviders(dataProviders: any) {
    if (dataProviders.length > 0) {
      this.loadingDataProviders = false;
    }
    this._dataProviders = dataProviders;
  }

  get dataProviders() {
    return this._dataProviders;
  }

  @Input()
  set index(index: any) {
    this._index = index;
  }

  get index() {
    return this._index;
  }

  @Input()
  set selectedBaselineChannels(selectedBaselineChannels: any) {
    this._selectedBaselineChannels = selectedBaselineChannels;
  }

  get selectedBaselineChannels() {
    return this._selectedBaselineChannels;
  }

  @Input()
  set count(count: any) {
    this._count = count;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  @Input()
  set sourceIds(sourceIds: any) {
    this._sourceIds = sourceIds;
  }

  get sourceIds() {
    return this._sourceIds;
  }

  @Input() baselineDefinitions: BaselineDefinition[];

  @Input() userLocale;

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get isViewMode() {
    return this.mode === this.VIEW;
  }

  get displayLabel() {
    if (this.baselinePoint.displayLabels && this.baselinePoint.displayLabels[this.userLocale]) {
      return this.baselinePoint.displayLabels[this.userLocale];
    }
    return this.baselinePoint.displayLabel;
  }

  get baselinePointChannel() {
    if (this._baselinePoint.channelId && this.channels && this.channels.length > 0) {
      const selectedChannel = this.channels.find((channel: any) => this._baselinePoint.channelId + '' === channel.id);
      if (selectedChannel) {
        return selectedChannel.displayLabel;
      }
    }
  }

  get baselinePointSourceId() {
    if (this._baselinePoint) {
      return this._baselinePoint.sourceId;
    }
  }

  get pointId() {
    if (this._baselinePoint) {
      return this._baselinePoint.id;
    }
  }

  get canDelete() {
    return this._count > 1;
  }

  constructor(
    private supportingDataService: SupportingDataService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private formValidatorService: FormValidatorService,
    private form: NgForm,
  ) {
    const routeSub = this.route.queryParams.subscribe(async queryParams => {
      if (queryParams.clone) {
        this.clone = true;
      }
    });
    this.nameLabel = this.translateService.instant('baseline_point.create.name');
    this.namePlaceholder = this.translateService.instant('baseline_point.create.placeholder.name');
    this.descriptionLabel = this.translateService.instant('baseline_point.create.description');
    this.descriptionPlaceholder = this.translateService.instant('baseline_point.create.placeholder.description');
    const channelsSub = this.supportingDataService.channels$.subscribe(async channels => {
      this.channels = this.supportingDataService.getBaselineChannels();
      if (this.channels && this.channels.length >= 0) {
        this.loadingChannels = false;
      }
    });

    this.panelOpenedState = false;
    this.subscriptions.push(...[channelsSub, routeSub]);
  }

  handleEdit(e: Event) {
    e.stopImmediatePropagation();
    if (this.parent) {
      const { id } = this.parent;
      this.router.navigate([`details/${id}/edit`]);
    }
  }

  addBaselinePoint(e: Event) {
    e.stopImmediatePropagation();
    this.addDynamicBaselinePoint.emit();
  }

  deleteBaselinePoint(e: Event, index: number) {
    e.stopImmediatePropagation();
    const name = this.baselinePoint.displayLabels[this.userLocale];
    let dialogRef = this.dialog.open(DeleteBaseLineDialogComponent, {
      width: '400px',
      data: {
        index: index,
        displayLabel: name,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteDynamicBaselinePoint.emit(index);
      }
    });
  }

  onChannelChange($event) {
    this.updateUsedChannels($event.value);
    this.updateChannelId($event.value);
    this.updateDisplayLabel($event.value);
  }

  updateUsedChannels(channelId: string) {
    if (!this.usedChannels || !this.usedChannels.includes(channelId)) {
      this.updateSelectedBaselinePointChannels.emit();
    }
  }

  updateDisplayLabel(channelId) {
    if (this.isViewMode || (this.firstTime && channelId !== '-1')) {
      this.firstTime = false;
      return;
    }
    this.firstTime = false;
    let baselinePointName = '';
    this.selectedRegistrationName = this.selectedRegistrationName ? this.selectedRegistrationName.trim() : '';
    let defaultBaselineName = this.index ? `Baseline ${this.index}` : 'Baseline';
    if (this.selectedRegistrationName) {
      baselinePointName = this.selectedRegistrationName;
    } else {
      baselinePointName = defaultBaselineName;
    }

    if (channelId !== '-1' && this.channels) {
      const channel = this.channels.find(channel => channel.id == channelId);
      if (channel) {
        baselinePointName = `${baselinePointName} - ${channel.displayLabel}`;
      }
    }
    this._baselinePoint.displayLabels[this.userLocale] = baselinePointName;
  }

  updateChannelId(deliveredChannelId: string) {
    if (typeof deliveredChannelId != 'undefined') {
      let canonicalChannel = this.channels.find(channel => {
        if (channel.isCanonical === true && channel.conversions && channel.conversions.inboundConversions) {
          const requiredChannelIds = channel.conversions.inboundConversions.reduce((acc, currentConversion) => {
            return [...acc, ...currentConversion.requiredChannelIds];
          }, []);
          return requiredChannelIds.includes(deliveredChannelId);
        }
      });
      this.baselinePoint.channelId = canonicalChannel?.id.toString() || deliveredChannelId.toString();
    }
  }

  checkExistingBaselinePointChannels(channelId: string) {
    return this._selectedBaselineChannels && this._selectedBaselineChannels.includes(channelId);
  }

  checkSourceId($event) {
    if (this._sourceIds && !this._sourceIds.includes($event.target.value)) {
      this.updateSourceIds.emit();
    }
  }

  channelCompare(channel1: any, channel2: any) {
    if (typeof channel1 === 'string') {
      channel1 = parseInt(channel1);
    }

    if (typeof channel2 === 'string') {
      channel2 = parseInt(channel2);
    }
    return channel1 === channel2;
  }

  reportingIntervalCompare(interval1: any, interval2: any) {
    if (typeof interval1 === 'string') {
      interval1 = parseInt(interval1);
    }

    if (typeof interval2 === 'string') {
      interval2 = parseInt(interval2);
    }
    return interval1 === interval2;
  }

  typeCompare(type1: any, type2: any) {
    return type1 && type2 ? type1 === type2 : false;
  }

  getBaselinePointReportingInterval() {
    if (this._reportingIntervals.length > 0 && this._baselinePoint) {
      const reportingInterval = this._reportingIntervals.find(
        (interval: any) => this._baselinePoint.reportingInterval == interval.durationInMilliseconds,
      );
      if (reportingInterval) {
        return reportingInterval.displayLabel;
      }

      return '';
    }
    return '';
  }

  getSelectedBaselineDefinitionLabel() {
    if (this.baselineDefinitions && this.baselineDefinitions.length > 0) {
      const baselineDefinition = this.baselineDefinitions.find(
        baselineDefinition => baselineDefinition.id == this._baselinePoint.baselineDefinitionId,
      );
      if (baselineDefinition) {
        return baselineDefinition.blTemplateName;
      }
      return 'registration.baseline_definition_id_not_found';
    }
    return 'registration.no_baseline_definitions';
  }

  getDisplayLabelsId() {
    return `${this.displayLabelsId}_${this._index}`;
  }

  getDescriptionLabelsId() {
    return `baseline_descriptions_${this._index}`;
  }

  getSelectedDataProviderLabel() {
    if (this.selectedDataProvider && this._dataProviders && this._dataProviders.length > 0) {
      const provider = this._dataProviders.find(provider => provider.id === this.selectedDataProvider);
      if (provider) {
        return provider.displayLabel;
      }
      return '';
    }

    return '';
  }

  handleDataProviderChange($event) {
    this.selectedDataProvider = $event.value;
  }

  async ngOnInit() {
    let baselinePointName = '';
    await this.supportingDataService.getChannels();
    await this.supportingDataService.setReportingIntervals();
    if (this.baselinePoint.toEdit) {
      this.baselinePanel.open();
    }
    if (this.selectedRegistrationName) {
      baselinePointName = `${this.selectedRegistrationName} Baseline ${this.index}` || '';
      for (const ctrlValue in this.form.form['controls']) {
        if (ctrlValue.match(/^baselinePointsForm_(\d)*$/)) {
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsTouched();
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsDirty();
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.setErrors(null);
        }
      }
    } else {
      baselinePointName = `Baseline ${this.index}` || '';
      for (const ctrlValue in this.form.form['controls']) {
        if (ctrlValue.match(/^baselinePointsForm_(\d)*$/)) {
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsTouched();
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.markAsDirty();
          this.form.form['controls'][ctrlValue]['controls'].displayLabels?.setErrors(null);
        }
      }
    }
    if (this.isCreateMode) {
      this._baselinePoint.displayLabels.en_US = baselinePointName;
    }
    if (this.isCreateMode) {
      if (this.clone) {
        return;
      }
      this.dataProviderSelectedByDefault = this.dataProviders.filter(dataProvElement => {
        const dataProviderToFind = 'Baseline Data Provider';
        return dataProvElement.displayLabel.trim() === dataProviderToFind.trim();
      });
      this.baselinePoint.dataProviderId = this.dataProviderSelectedByDefault[0]?.id;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  expandPanel() {
    this.formValidatorService.triggerValidation(this.form.form);
    this.panelOpenedState = true;
  }

  onSourceIdChange() {
    this.formValidatorService.triggerSourceIdValidation(this.form.form);
  }

  onSourceIdPaste() {
    setTimeout(() => {
      this.formValidatorService.triggerSourceIdValidation(this.form.form);
    }, 7000);
  }
}
